<template>
    <div>
      <!-- Drawer抽屉 -->
      <el-dialog  :visible.sync="visible" width="50%" >
        <div :style="{maxHeight: maxHeight}" class="drawer-content">
          <Form ref="form" :model="form" :rules="formValidate"  label-position="top">
            <Row :gutter="16">
              <Col span="12">
                <FormItem label="楼宇名称" prop="buildName" >
                  <Input v-model="form.buildName" clearable/>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="简称" prop="shortName" >
                  <Input v-model="form.shortName" clearable/>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="地址" prop="buildAddr" >
                  <Input v-model="form.buildAddr" clearable/>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="总层数" prop="totalFloor" >
                  <Input v-model="form.totalFloor" clearable/>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="联系电话" prop="contractPhone" >
                  <Input v-model="form.contractPhone" clearable/>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="开发商" prop="builder" >
                  <Input v-model="form.builder" clearable/>
                </FormItem>
              </Col>
              <Col span="24">
                <FormItem label="简介" prop="buildDesc" >
                  <Input v-model="form.buildDesc" type="textarea" :rows="4" maxlength="250" show-word-limit/>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
        <div class="drawer-footer br" style="text-align:right" slot="footer" v-show="type!='0'">
          <el-button  type="primary" :loading="submitLoading" @click="submit">提交</el-button>
          <el-button @click="visible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  // 根据你的实际请求api.js位置路径修改
  import { addBuildingConfig, editBuildingConfig } from "@/api/index";
  export default {
    name: "addEdit",
    components: {
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object
      },
      type: {
        type: String,
        default: "0"
      }
    },
    data() {
      return {
        visible: this.value,
        title: "",
        submitLoading: false,
        maxHeight: 510,
        form: { // 添加或编辑表单对象初始化数据
          buildName: "",
          shortName: "",
          buildAddr: "",
          totalFloor: "",
          contractPhone: "",
          builder: "",
          buildDesc: "",
          remark: "",
        },
        // 表单验证规则
        formValidate: {
        buildName: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
        buildAddr: [{ type: "string", required: true, message: "请输入", trigger: "blur" }],
      }
      };
    },
    methods: {
      init() {
      },
      submit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.type == "1") {
              // 编辑
              this.submitLoading = true;
              editBuildingConfig(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            } else {
              // 添加
              this.submitLoading = true;
              addBuildingConfig(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            }
          }
        });
      },
      setCurrentValue(value) {
        if (value === this.visible) {
          return;
        }
        if (this.type == "1") {
          this.title = "编辑";
          this.maxHeight =
            Number(document.documentElement.clientHeight - 121) + "px";
        } else if (this.type == "2") {
          this.title = "添加";
          this.maxHeight =
            Number(document.documentElement.clientHeight - 121) + "px";
        } else {
          this.title = "详细信息";
          this.maxHeight = "100%";
        }
        // 清空数据
        //this.$refs.form.resetFields();
        if (this.type == "0" || this.type == "1") {
          // 回显数据处理
          this.form = this.data;
        } else {
          // 添加
          this.form = {
          buildName: "",
          shortName: "",
          buildAddr: "",
          totalFloor: "",
          contractPhone: "",
          builder: "",
          buildDesc: "",
          remark: ""
          }
          //delete this.form.id;
        }
        this.visible = value;
      }
    },
    watch: {
      value(val) {
        // alert(val)
        console.log(val)
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      }
    },
    mounted() {
      this.init();
    }
  };
  </script>
  
  <style lang="less">
  @import "@/styles/drawer-common.less";
  </style>